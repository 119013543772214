<template>
    <div :class="route.name" class="site-body">
        <GlobalNotificationBanner />
        <GlobalHeader />
        <NuxtPage />
        <LazyGlobalFooter />
    </div>  
</template>

<script setup>
    const route = useRoute();
    const LazyGlobalFooter = defineAsyncComponent(() => import("@/components/global/Footer.vue"));

    if (process.env.NUXT_ENV === "staging") {
        useHead({
            script: [
                {
                    innerHTML: `(function(k,s) {
                        s=document.createElement('script');s.module=true;s.async=true;
                        s.src="https://cdn.feedbucket.app/assets/feedbucket.js";
                        s.dataset.feedbucket=k;document.head.appendChild(s);
                    })('p2XufyybCZJEXvwXMRdY')`,
                },
            ],
        });
    }
</script>
